import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export const BoardOfDirectors = () => {
  const executiveCommittee = [
    {
      name: 'Mohammed Faiyaz Zaman',
      position: 'President',
      imageSrc: 'images/President.png',
      description: 'Faiyaz Zaman is a retired Engineering Manager who worked for 20 plus years in Silicon Valley, and he is also a licensed Real Estate Agent. He passionately engages in Community Service, striving to make a difference both here and abroad.'
    },
    {
      name: 'Aziz Choudhury',
      position: 'Project Coordinater',
      imageSrc: 'images/Aziz.jpg',
      description: 'Aziz Choudhury is a compassionate and dedicated social worker with nearly 15 plus  years of experience in working with the South Asian and other communities in our area. With a strong background in  community engagement, he has worked tirelessly to empower individuals and families to overcome challenges and achieve their goals. He  has developed expertise in cultural and sports advocacy and has built strong relationships with clients, colleagues, and community partners. He is committed to continuing to make a positive impact in the lives of others through social activity.'
    },
    {
      name: 'MD Rafiq Islam',
      position: 'General Secretary',
      imageSrc: 'images/Father.jpg',
      description: 'Md Islam is deeply passionate about dedicating his time and efforts to assist others, particularly focusing on serving the underprivileged communities in Bangladesh and across the globe. His commitment to community service is driven by a strong desire to make a meaningful impact in the lives of those in need.'
    },
    {
      name: 'Mir-a-Taj (Tithi) Choudhury',
      position: 'Social',
      imageSrc: 'images/TithiChoudhury.png',
      description: 'Mir-a-Taj Chowdhury serves as an Instructional Assistant at the Evergreen School District. She holds a B.Comm. degree from the University Womens Federation. Beyond her educational pursuits and dedication to her professional role, Mir-a-Taj is deeply passionate about community engagement. She actively seeks opportunities in volunteering and outreach, demonstrating a firm commitment to fostering positive change and growth within her community.'
    },
    {
      name: 'Nasima Reza',
      position: 'Treasurer',
      imageSrc: 'images/Reza.jpg',
      description: 'Nasima Reza works in Finance at a cyber-security company. She loves working with numbers and never misses an opportunity to help those in need.'
    },
    {
      name: 'Anupriya Islam',
      position: 'Webmaster',
      imageSrc: 'images/Anupriya_GOP.jpg',
      description: 'Anupriya Islam, a 3rd year student at UC Berkeley, harbors a deep passion for coding. Driven by her dedication to leave a positive impact, she actively seeks opportunities to benefit and uplift her community through technology.'
    },
    {
      name: 'Tanzir Hasan',
      position: 'Communications',
      imageSrc: 'images/placeholder.png',
    },
    {
      name: 'Yusuf Saad',
      position: 'Funeral Director',
      imageSrc: 'images/placeholder.png',
    }

  ];

  const advisoryBoard = [
    {
      name: 'Mohammad Ibrahim',
      position: 'Advisory Member',
      imageSrc: 'images/IbrahimMohammad.png',
      description: "Mohammad Ibrahim is a retired high-tech executive (GE, Intel, AMD, National Semiconductor) dedicated full time to community support (Funeral, Burial, Mental Health, Family Counselling)."
    },
    {
      name: 'Khalid Azim',
      position: 'Advisory Member',
      imageSrc: 'images/placeholder.png',
      description: 'Dr. Khalid Azim is an engineer by training and, along with his wife, has been an active member of the Bay area community for last 40 years. He holds a Ph.D. in EECS from U.C. Berkely. Currently he is vice-president of ASIC Engineering at SigmaSense and is based in Fremont, California.'
    },
    {
      name: 'Sayeed Shaheen',
      position: 'Advisory Member',
      imageSrc: 'images/ShaheenSayeed.jpg',
      description: 'Shaheen Sayeed is a computer engineer by profession living in San Jose, CA. Involved with numerous non-profit organizations and passionate about community outreach.'
    },
    {
      name: 'Monowar H Khan',
      position: 'Advisory Member',
      imageSrc: 'images/MonowarKhan.jpg',
      description: 'Monowar Khan works as an engineering leadership role in Silicon Valley.  He is passionate about helping the needy and being involved with humanitarian organizations.'
    },
    {
      name: 'Mohammad Billah Rana',
      position: 'Advisory Member',
      imageSrc: 'images/MohammadBillahRana.jpeg',
      description: "Mohammad Billah (Rana) has lived in Sacramento, California since 1995 and is the President of University Inn Chico, LLC, as well as an active volunteer holding vice-presidential roles in SABAA and EBC and volunteering with the Greater Sacramento Muslim community."
    },
    {
      name: 'Mohammad Zafar Ullah',
      position: 'Advisory Member',
      imageSrc: 'images/placeholder.png',
    },
    {
      name: 'Musheer Khan',
      position: 'Advisory Member',
      imageSrc: 'images/placeholder.png',
    },
  ];

  const volunteers = [
    {
      name: 'Iram Mustafa',
      position: 'Volunteer',
      imageSrc: 'images/placeholder.png',
    },
    {
      name: 'Sultana Zafar',
      position: 'Volunteer',
      imageSrc: 'images/placeholder.png',
    },
    {
      name: 'Mushtaq Syed',
      position: 'Volunteer',
      imageSrc: 'images/placeholder.png',
    },
    {
      name: 'Saleq Zaman',
      position: 'Volunteer',
      imageSrc: 'images/placeholder.png',
    },
    {
      name: 'Nushrat Kulshum Tina',
      position: 'Volunteer',
      imageSrc: 'images/placeholder.png',
    },
    {
      name: 'Mashuque Rahman',
      position: 'Volunteer',
      imageSrc: 'images/placeholder.png',
    }
  ];

  const createCardColumns = (members, columns) => {
    const membersPerColumn = Math.ceil(members.length / columns);

    return Array.from({ length: columns }, (_, columnIndex) => (
      <Col key={columnIndex}>
        {members.slice(
          columnIndex * membersPerColumn,
          (columnIndex + 1) * membersPerColumn
        ).map((member, index) => (
          <Card key={index} className="mb-4">
            <Card.Img src={member.imageSrc} alt={member.name} className="square-image" />
            <Card.Body>
              <Card.Title>{member.name}</Card.Title>
              <Card.Text>{member.position}</Card.Text>
              <Card.Text>{member.description}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </Col>
    ));
  };

  return (
    <div className='bod'>
      <div className="image-banner">
        <img
          src="images/HomeSlider_5.jpg"
          alt="Banner"
          className="banner-image"
        />
        <div className="banner-text">
          <h1 className="banner-text-bod">GARDEN OF PEACE TEAM</h1>
          <p className="banner-text-sub">The Garden of Peace team offers an open-door policy to the community members. You can provide your valuable feedback via phone, email, and webpage.</p>
        </div>
        <div className="gradient-banner-overlay"></div>
      </div>
      <Container className='bod-cards'>
        <div className="mb-4">
          <h5 className='heading-bod'>EXECUTIVE COMMITTEE</h5>
          <p>Responsible for the governance and operation of the project</p>
          <Row>{createCardColumns(executiveCommittee, 4)}</Row>
        </div>
        <div className="mb-4">
          <h5 className='heading-bod'>ADVISORY BOARD</h5>
          <p>Provides strategic guidance to the Executive committee</p>
          <Row>{createCardColumns(advisoryBoard, 4)}</Row>
        </div>
        <div>
          <h5 className='heading-bod'>VOLUNTEERS</h5>
          <p>Takes on the various day to day operational responsibilities for the project</p>
          <Row>{createCardColumns(volunteers, 4)}</Row>
        </div>
      </Container>
    </div>
  );
}

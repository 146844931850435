import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const SLIDE_DURATION = 5000; // 5 seconds per slide

const HomeBanner = () => {
  const [progress, setProgress] = useState(0);
  const swiperRef = useRef(null);

  return (
    <div className="animate-fadeIn w-full relative">
      <Swiper
        ref={swiperRef}
        modules={[Autoplay, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: SLIDE_DURATION,
          disableOnInteraction: false,
        }}
        loop={true}
        speed={1500} // Smooth transition
        pagination={{ clickable: true }}
        onAutoplayTimeLeft={(_, timeLeft) => {
          setProgress(((SLIDE_DURATION - timeLeft) / SLIDE_DURATION) * 100);
        }}
      >
        {/* Slide 1 */}
        <SwiperSlide>
          <div className="relative w-full h-96">
            <img src="/images/HomeSlider14.jpg" alt="Slide 1" className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-center px-6">
              <h2 className="text-white text-3xl md:text-4xl font-bold">
                Providing Dignified Islamic Funeral Services
              </h2>
              <p className="text-white mt-2 text-lg md:text-xl">
                Serving the Muslim community with compassion and care.
              </p>
              <a href="/aboutus" className="no-underline mt-4 px-6 py-2 bg-green-700 text-white text-lg font-semibold rounded-md hover:bg-green-800 transition">
                Learn More
              </a>
            </div>
          </div>
        </SwiperSlide>

        {/* Slide 2 */}
        <SwiperSlide>
          <div className="relative w-full h-96">
            <img src="/images/HomeSlider11.jpg" alt="Slide 2" className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-center px-6">
              <h2 className="text-white text-3xl md:text-4xl font-bold">
                A Resting Place for Our Loved Ones
              </h2>
              <p className="text-white mt-2 text-lg md:text-xl">
                A peaceful cemetery dedicated to the community.
              </p>
              <a href="/contactus" className="no-underline mt-4 px-6 py-2 bg-green-700 text-white text-lg font-semibold rounded-md hover:bg-green-800 transition">
                Contact Us
              </a>
            </div>
          </div>
        </SwiperSlide>

        {/* Slide 3 */}
        <SwiperSlide>
          <div className="relative w-full h-96">
            <img src="/images/HomeSlider12.jpg" alt="Slide 3" className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-center px-6">
              <h2 className="text-white text-3xl md:text-4xl font-bold">
                Support the Future of Our Community
              </h2>
              <p className="text-white mt-2 text-lg md:text-xl">
                Your donation helps maintain and expand our services.
              </p>
              <a href="/donate" className="no-underline mt-4 px-6 py-2 bg-green-700 text-white text-lg font-semibold rounded-md hover:bg-green-800 transition">
                Donate Now
              </a>
            </div>
          </div>
        </SwiperSlide>

        {/* Slide 4 */}
        <SwiperSlide>
          <div className="relative w-full h-96">
            <img src="/images/HomeSlider14.jpg" alt="Slide 4" className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-center px-6">
              <h2 className="text-white text-3xl md:text-4xl font-bold">
                Join Us in This Noble Cause
              </h2>
              <p className="text-white mt-2 text-lg md:text-xl">
                Become a volunteer and support families in need.
              </p>
              <a href="/volunteering" className="no-underline mt-4 px-6 py-2 bg-green-700 text-white text-lg font-semibold rounded-md hover:bg-green-800 transition">
                Get Involved
              </a>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      {/* Progress Bar - Synced with Slide Timing */}
      <div className="absolute bottom-0 left-0 w-full h-2 bg-gray-300 z-10">
        <div
          className="h-2 bg-green-700 transition-all"
          style={{ width: `${progress}%`, transition: "width 0.1s linear" }}
        ></div>
      </div>
    </div>
  );
};

export default HomeBanner;

/* eslint-disable no-unused-vars */

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import './gardenofpeace.css';
import NavbarComponent from './componenets/NavbarComponent'; // Ensure this matches the actual file name
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PopUp from './PopUp';
import { AboutUs } from './AboutUs';
import { Mission } from './Mission';
import { BoardOfDirectors } from './BoardOfDirectors';
import { BurialEtiquette } from './BurialEtiquette';
import { JanazahProcess } from './JanazahProcess';
import { ContactUs } from './ContactUs';
import { WhyThisProject } from './WhyThisProject';
import { Gallery } from './Gallery';
import { FuneralServices } from './FuneralServices';
import { BurialRequest } from './BurialRequest';
import { DuaAndPrayers } from './DuaAndPrayers';
import { Donate } from './Donate';
import { Home } from './Home';
import { DonateZelle } from './DonateZelle';
import { BurialPlot } from './BurialPlot';
// import { Presentation } from './Presentation';
import { QNA } from './QNA';
import { FundraisingInfo } from './FundraisingInfo';
import { Goals } from './Goals';
// import { Event } from './Event';
import { Training } from './Training';
import { Volunteering } from './Volunteering';

const App = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    import('bootstrap').then((bootstrap) => {
      console.log('Bootstrap loaded:', bootstrap);
    });
  }, []);

  useEffect(() => {
    // Show the modal when the component mounts
    setShowModal(true);
  }, []);

  const handleClose = () => {
    // Hide the modal
    setShowModal(false);
  };

  return (
    <BrowserRouter>
      <NavbarComponent />
      <ToastContainer />
      <PopUp show={showModal} onClose={handleClose} />

      {/* Routing Configuration */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/services" element={<Mission />} />
        <Route path="/boardofdirectors" element={<BoardOfDirectors />} />
        <Route path="/burialetiquette" element={<BurialEtiquette />} />
        <Route path="/janazahprocess" element={<JanazahProcess />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/whythisproject" element={<WhyThisProject />} />
        <Route path="/QNA" element={<QNA />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/progressgoals2023" element={<Goals />} />
        <Route path="/funraising2023presentation" element={<FundraisingInfo />} />
        <Route path="/duaandprayers" element={<DuaAndPrayers />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/funeralservices" element={<FuneralServices />} />
        <Route path="/burialrequest" element={<BurialRequest />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/donatezellepaypal" element={<DonateZelle />} />
        <Route path="/training" element={<Training />} />
        <Route path="/volunteering" element={<Volunteering />} />
        <Route path="/burialplotpledgeform" element={<BurialPlot />} />
      </Routes>

      {/* Uncomment if you have a Footer component */}
      {/* <Footer /> */}
    </BrowserRouter>
  );
};

export default App;

import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const NavbarComponent = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isActive = (path) => location.pathname === path ? 'after:w-full after:border-white' : '';

  const aboutUsRef = useRef(null);
  const opportunitiesRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {/* Contact Bar */}
      <div className="animate-fadeIn font-inria bg-green-900 text-white text-sm py-2 px-4 flex justify-end space-x-6 items-center">
        {/* Phone Icon & Number */}
        <div className="flex items-center space-x-2">
          <i className="fas fa-phone-alt text-white text-base"></i>
          <span>(669) 214-6771</span>
        </div>

        {/* Location Icon & Address */}
        <div className="flex items-center space-x-2">
          <i className="fas fa-map-marker-alt text-white text-base"></i>
          <span>17205 S Jasper Sears Road, Santa Nella, CA 95322</span>
        </div>
      </div>

      {/* Top White Bar with Logo and Title */}
      <div className="animate-fadeIn bg-white shadow-md py-3 flex justify-between items-center px-6">
        <div className="no-underline flex items-center space-x-4 mx-auto lg:mx-0">
          <Link to="/home" className="no-underline flex items-center space-x-4">
            <img src="/images/logo2.png" alt="Garden of Peace Logo" className="h-12" />
            <h1 className="no-underline font-inria text-3xl font-bold text-green-900">GARDEN OF PEACE</h1>
          </Link>
        </div>
        {/* Hamburger Icon for Mobile */}
        <div className="lg:hidden">
          <button onClick={toggleMobileMenu} className="text-green-900 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Main Navigation Bar */}
      <nav className="animate-fadeIn font-inria bg-green-800 bg-opacity-80 backdrop-filter backdrop-blur-lg shadow-md px-6 py-2 flex justify-center items-center relative z-[50] sticky top-0">
        <div className="container mx-auto flex justify-center items-center">
          {/* Desktop Menu */}
          <ul className="hidden lg:flex space-x-14 font-semibold text-lg items-center h-10 pt-3">
            <li className="relative group">
              <Link to="/home" className={`text-white no-underline hover:text-green-500 transition-all duration-300 ${isActive('/home')}`}>
                Home
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-white group-hover:w-full transition-all duration-300"></span>
              </Link>
            </li>
            
            {/* About Us Dropdown */}
            <Menu as="div" className="relative group">
              <Menu.Button className={`text-white no-underline hover:text-green-500 transition-all duration-300 focus:outline-none ${isActive('/aboutus')}`}>
                About Us ▼
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-white group-hover:w-full transition-all duration-300"></span>
              </Menu.Button>
              <Transition 
                as={Fragment} 
                enter="transition ease-out duration-200" 
                enterFrom="opacity-0 scale-95" 
                enterTo="opacity-100 scale-100" 
                leave="transition ease-in duration-150" 
                leaveFrom="opacity-100 scale-100" 
                leaveTo="opacity-0 scale-95">
                <Menu.Items 
                  ref={aboutUsRef} 
                  className="absolute left-0 mt-2 w-56 bg-white text-black border rounded-md shadow-lg z-[9999]"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/aboutus" className="block px-4 py-2 text-black hover:bg-green-200 no-underline">
                        About Garden of Peace
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/whythisproject" className="block px-4 py-2 text-black hover:bg-green-200 no-underline">
                        Why This Project
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/gallery" className="block px-4 py-2 text-black hover:bg-green-200 no-underline">
                        Gallery
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>

            <li className="relative group">
              <Link to="/services" className={`text-white no-underline hover:text-green-500 transition-all duration-300 ${isActive('/services')}`}>
                Services
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-white group-hover:w-full transition-all duration-300"></span>
              </Link>
            </li>

            {/* Opportunities Dropdown */}
            <Menu as="div" className="relative group">
              <Menu.Button className={`text-white no-underline hover:text-green-500 transition-all duration-300 focus:outline-none ${isActive('/opportunities')}`}>
                Opportunities ▼
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-white group-hover:w-full transition-all duration-300"></span>
              </Menu.Button>
              <Transition 
                as={Fragment} 
                enter="transition ease-out duration-200" 
                enterFrom="opacity-0 scale-95" 
                enterTo="opacity-100 scale-100" 
                leave="transition ease-in duration-150" 
                leaveFrom="opacity-100 scale-100" 
                leaveTo="opacity-0 scale-95">
                <Menu.Items 
                  ref={opportunitiesRef} 
                  className="absolute left-0 mt-2 w-56 bg-white text-black border rounded-md shadow-lg z-[9999]"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/training" className="block px-4 py-2 text-black hover:bg-green-200 no-underline">
                        Training
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/volunteering" className="block px-4 py-2 text-black hover:bg-green-200 no-underline">
                        Volunteering
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>

            <li className="relative group">
              <Link to="/boardofdirectors" className={`text-white no-underline hover:text-green-500 transition-all duration-300 ${isActive('/boardofdirectors')}`}>
                Team
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-white group-hover:w-full transition-all duration-300"></span>
              </Link>
            </li>
            <li className="relative group">
              <Link to="/contactus" className={`text-white no-underline hover:text-green-500 transition-all duration-300 ${isActive('/contactus')}`}>
                Contact Us
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-white group-hover:w-full transition-all duration-300"></span>
              </Link>
            </li>
            <li>
              <Link to="/donate" className="bg-white text-green-800 px-6 py-2 rounded-full hover:text-green-800 hover:bg-green-500 transition duration-200 no-underline flex items-center justify-center">
                Donate
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden sticky top-[140px] left-0 w-full h-auto font-inria text-xl bg-green-800 bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-md z-[9999] overflow-y-auto">
          <div className="flex justify-end p-6">
            <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <ul className="flex flex-col space-y-4 p-4 items-center">
            <li>
              <Link to="/home" className={`text-white no-underline hover:text-green-500 transition-all duration-300 ${isActive('/home')}`}>
                Home
              </Link>
            </li>
            
            {/* About Us Dropdown in Mobile Menu */}
            <Menu as="div" className="relative group">
              <Menu.Button className={`text-white no-underline hover:text-green-500 focus:outline-none transition-all duration-300 ${isActive('/aboutus')}`}>
                About Us ▼
              </Menu.Button>
              <Transition 
                as={Fragment} 
                enter="transition ease-out duration-200" 
                enterFrom="opacity-0 scale-95" 
                enterTo="opacity-100 scale-100" 
                leave="transition ease-in duration-150" 
                leaveFrom="opacity-100 scale-100" 
                leaveTo="opacity-0 scale-95">
                <Menu.Items className="text-white no-underline mt-2 w-56 bg-green-700 border rounded-md shadow-lg">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/aboutus" className="text-white block px-4 py-2 hover:bg-green-600 no-underline transition-all duration-300">
                        About Garden of Peace
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/whythisproject" className="text-white block px-4 py-2 hover:bg-green-600 no-underline transition-all duration-300">
                        Why This Project
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/gallery" className="text-white block px-4 py-2 hover:bg-green-600 no-underline transition-all duration-300">
                        Gallery
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>

            <li>
              <Link to="/services" className={`text-white no-underline hover:text-green-500 transition-all duration-300 ${isActive('/services')}`}>
                Services
              </Link>
            </li>

            {/* Opportunities Dropdown in Mobile Menu */}
            <Menu as="div" className="relative group">
              <Menu.Button className={`text-white no-underline hover:text-green-500 focus:outline-none transition-all duration-300 ${isActive('/opportunities')}`}>
                Opportunities ▼
              </Menu.Button>
              <Transition 
                as={Fragment} 
                enter="transition ease-out duration-200" 
                enterFrom="opacity-0 scale-95" 
                enterTo="opacity-100 scale-100" 
                leave="transition ease-in duration-150" 
                leaveFrom="opacity-100 scale-100" 
                leaveTo="opacity-0 scale-95">
                <Menu.Items className="text-white no-underline mt-2 w-56 bg-green-700 text-white border rounded-md shadow-lg">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/training" className="text-white block px-4 py-2 hover:bg-green-600 no-underline transition-all duration-300">
                        Training
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/volunteering" className="text-white block px-4 py-2 hover:bg-green-600 no-underline transition-all duration-300">
                        Volunteering
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>

            <li>
              <Link to="/boardofdirectors" className={`text-white no-underline hover:text-green-500 transition-all duration-300 ${isActive('/boardofdirectors')}`}>
                Team
              </Link>
            </li>
            <li>
              <Link to="/contactus" className={`text-white no-underline hover:text-green-500 transition-all duration-300 ${isActive('/contactus')}`}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/donate" className="bg-white text-green-800 px-6 py-2 rounded-full hover:text-green-800 hover:bg-green-500 transition duration-200 no-underline flex items-center justify-center">
                Donate
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default NavbarComponent;
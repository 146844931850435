import React from 'react';
import './gardenofpeace.css';

const PopUp = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close" onClick={onClose}>&times;</button>
        <h5 className='font-inria'>As-Salamu Alaikum,</h5>
        <p>
        The Bay Area Muslim community's Garden of Peace project has secured 160 acres of land in Santa Nella, CA, to establish a cemetery.
         <b>We are working diligently to prepare the property for burials by September 2025, Inshallah.</b>
        </p>
        <p>
        🚧 We are experiencing delays due to permit approvals and the preparation of prayer and washroom facilities. 
        We appreciate your patience and continued support as we work towards completing these essential requirements.
        </p>
        <p>
        Your generous contributions will help us reach this milestone and make this cemetery a reality, Inshallah.
        </p>
        <p>Ways to donate:</p>
          <ul>
            <li>
            <u>📬 Check:</u> PO Box 3216, Santa Clara, CA 95055
            </li>
            <li>
            <u>💳 Zelle:</u> gardenofpeace.usa@gmail.com
            </li>
          </ul>
        <p>
          <b>Additionally, we are seeking dedicated and compassionate <a href="http://www.gardenofpeaceus.org/volunteering">volunteers</a> to join us in supporting bereaved family members during the funeral and burial process.</b> Guidance and training will be provided to all interested volunteers.
        </p>
          For more information, please contact us at:
          <ul>
            <li>
            <u>Phone Number:</u> (669) 214-6771
            </li>
            <li>
            <u>Email:</u> gardenofpeace.usa@gmail.com
            </li>
            <li>
            <u>Website:</u> <a href="http://www.gardenofpeaceus.org">www.gardenofpeaceus.org</a>
            </li>
          </ul>          
        <p>
          Jazakallahu Khairan,
          <br></br>
          <b className='font-inria text-lg'> Garden of Peace Team</b>
        </p>
      </div>
    </div>
  );
};

export default PopUp;
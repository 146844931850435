import React from 'react';
import HomeBanner from './componenets/HomeBanner';

export const Home = () => {
  return (
    <div className="animate-fadeIn home">
      <HomeBanner />

      {/* Quranic Verse Section */}
      <div className="py-12 bg-gray-100 text-center">
        <p className="text-5xl font-bold text-gray-800 pt-4">
          كُلُّ نَفۡسٖ ذَآئِقَةُ ٱلۡمَوۡتِۗ وَنَبۡلُوكُم بِٱلشَّرِّ وَٱلۡخَيۡرِ فِتۡنَةٗۖ وَإِلَيۡنَا تُرۡجَعُونَ
        </p>
        <p className="mt-4 mx-auto max-w-6xl p-6 text-2xl text-gray-600 italic">
          "Every soul will taste death. And We test you ˹O humanity˺ with good and evil as a trial, then to Us you will ˹all˺ be returned." 
          <span className="block text-gray-500 mt-2">(Surah Al-Anbiya 21:35)</span>
        </p>
      </div>

      {/* Welcome Section */}
      <div className="relative py-20 text-center group overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center transition-transform transform group-hover:scale-105 group-hover:blur-sm"
          style={{ backgroundImage: "url('/images/HomeSlider_10.jpg')" }}
        ></div>
        <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-60 transition duration-300"></div>
        <div className="relative z-10 px-6">
          <h2 className="font-inria text-4xl font-bold text-white">
            Welcome to Garden of Peace
          </h2>
          <p className="mt-4 text-xl text-white max-w-5xl mx-auto">
            Garden of Peace is a nonprofit organization dedicated to establishing a dignified Islamic cemetery
            in Northern California. Our mission is to provide a final resting place that aligns with Islamic values,
            ensuring every Muslim is buried with honor, compassion, and respect.
          </p>
          <a href="/aboutus" 
            className="no-underline mt-6 inline-block px-6 py-2 bg-green-700 text-white text-lg font-semibold rounded-full hover:bg-emerald-800 transition">
            Learn More
          </a>
        </div>
      </div>

      {/* Mission & Progress Section */}
      <div className="py-12 bg-gray-100 text-center">
        <h2 className="font-inria text-3xl font-bold text-gray-800">Our Mission & Progress</h2>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6 px-6">
        {/* Card 1 - The Issue */}
        <div className="p-6 bg-white rounded-lg shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-[0px_0px_30px_10px_rgba(34,197,94,0.6)]">
          <div className="flex justify-center text-emerald-500 text-6xl">
            <i className="fas fa-exclamation-circle"></i> {/* Font Awesome Issue Icon */}
          </div>
          <h3 className="font-inria mt-4 text-2xl font-bold text-gray-800">The Issue</h3>
          <p className="mt-2 text-gray-600">
            With limited Muslim cemetery space in Northern California, we are working to provide a lasting solution for the community.
          </p>
          <a href="/why-this-project" className="no-underline mt-4 inline-block px-6 py-2 text-white bg-emerald-600 rounded-lg hover:bg-emerald-800 transition">
            Why This Project?
          </a>
        </div>

        {/* Card 2 - Services */}
        <div className="p-6 bg-white rounded-lg shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-[0px_0px_30px_10px_rgba(59,130,246,0.6)]">
          <div className="flex justify-center text-emerald-500 text-6xl">
            <i className="fas fa-hands-helping"></i> {/* Font Awesome Services Icon */}
          </div>
          <h3 className="font-inria mt-4 text-2xl font-bold text-gray-800">Services We Offer</h3>
          <p className="mt-2 text-gray-600">
            We provide transportation, purification, and prayer arrangements, ensuring every burial honors Islamic traditions.
          </p>
          <a href="/services" className="no-underline mt-4 inline-block px-6 py-2 text-white bg-emerald-600 rounded-lg hover:bg-emerald-800 transition">
            Explore Services
          </a>
        </div>

        {/* Card 3 - Progress */}
        <div className="p-6 bg-white rounded-lg shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-[0px_0px_30px_10px_rgba(234,179,8,0.6)]">
          <div className="flex justify-center text-emerald-500 text-6xl">
            <i className="fas fa-chart-line"></i> {/* Font Awesome Progress Icon */}
          </div>
          <h3 className="font-inria mt-4 text-2xl font-bold text-gray-800">Our Progress</h3>
          <p className="mt-2 text-gray-600">
          We have secured 160 acres for a community cemetery and are actively preparing the property for burials by April 2025, Inshallah.
          </p>
          <a href="/progress" className="no-underline mt-4 inline-block px-6 py-2 text-white bg-emerald-600 rounded-lg hover:bg-emerald-800 transition">
            See Progress
          </a>
        </div>
      </div>
      </div>

      {/* How You Can Support Section */}
      <div className="relative text-center group overflow-hidden">
        {/* Background Image */}
        <div 
          className="absolute inset-0 bg-cover bg-center transition-transform transform group-hover:scale-105 group-hover:blur-sm"
          style={{ backgroundImage: "url('/images/Gallery_4.jpg')" }}
        ></div>

        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-60 transition duration-300"></div>

        {/* Content */}
        <div className="relative z-10 text-white text-center py-20 px-6">
          <h2 className="font-inria text-4xl font-bold">Join Our Mission</h2>
          <p className="mt-4 text-xl max-w-4xl mx-auto">
            Help us build and maintain the cemetery by donating or volunteering your time.
          </p>

          <div className="mt-6 flex justify-center gap-6">
            <a href="/donate" 
              className="text-lg no-underline bg-green-700 text-white px-6 py-2 rounded-full font-semibold hover:bg-emerald-800 transition">
              Donate Now
            </a>
            <a href="/volunteer" 
              className="text-lg no-underline bg-green-700 text-white px-6 py-2 rounded-full font-semibold hover:bg-emerald-800 transition">
              Volunteer
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
